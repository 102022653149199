import { h } from 'vue'
import type { SetupContext, SlotsType } from 'vue'

interface Props {
  level?: string | null
}

interface Slots {
  default: any
}

function YscHeading({ level = null }: Props, { slots }: SetupContext<any, SlotsType<Slots>>) {
  const config = {
    class: '',
  }

  if (level === '1') {
    config.class = 'font-semibold text-2xl md:text-3xl'
  }
  else if (level === '2') {
    config.class = 'font-semibold text-xl md:text-2xl'
  }
  else if (level === '3') {
    config.class = 'font-semibold text-base md:text-lg'
  }

  return h(`h${level}`, config, slots.default())
}
YscHeading.props = ['level']

export default YscHeading
